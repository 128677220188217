
.art__container{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: solid 1px black;
    padding: 5px; 
    margin: 5px;
    margin-bottom: 15px;
    font-family: sans-serif;
    text-align: center;
    min-width: 16%;
    max-width: 280px;
    background: white;
    border-radius: 4px;
  }

  .art__container img {
    width: 100%;
    height: auto;
  }

  .art__container {

  }
  
  .dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
  }
  
  .image {
    width: 300px;
  }
  
  .popup_b {
    border: solid 1px black !important;
    border-radius: 5px !important;
  }

  .endtitle {
  
    padding-left: 50px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    
  }
  
  .nav_text {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 10px;
    background-image: url("./images/bg.jpg");
    background-color: #d0cfce;
  }
  
  .menu-items{
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    padding-right: 25px;
    padding-bottom: 12px;
    gap: 15px;
    background-color: #d0cfce;
    border-top: 1px solid;
    border-image-source: linear-gradient(to right, #d0cfce00, #303030);
  }
  
  .menu-items:hover{
    cursor: pointer;
  }
  
  .border-gradient {
    border-top: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
  }
  
  /* link styles */
  .endtitle a {
    color: #000000;
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
  }
  
  /* Change the color of links on hover */
  .endtitle a:hover {
    color: white;
    text-shadow: 2px 2px #6363632f;
  
  }
  
  /* Add a green background color to the active link */
  .endtitle a.active {
    color: white;
  }
  
  /* Hide the link that should open and close the navbar on small screens */
  .endtitle .icon {
    display: none;
  } 
  
   /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the navbar (.icon) */
  @media screen and (max-width: 600px) {
    .navbar a:not(:first-child) {display: none;}
    .navbar a.icon {
      float: right;
      display: block;
    }
  }
  
  /* The "responsive" class is added to the navbar with JavaScript when the user clicks on the icon. This class makes the navbar look good on small screens (display the links vertically instead of horizontally) */
  @media screen and (max-width: 600px) {
    .navbar.responsive a.icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .navbar.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  } 
  
  #showMe {
    opacity: 0;
    -moz-animation: cssAnimation 3.5s;
    /* Firefox */
    -webkit-animation: cssAnimation 3.5s;
    /* Safari and Chrome */
    -o-animation: cssAnimation 3.5s;
    /* Opera */
    animation: cssAnimation 3.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @keyframes cssAnimation {
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  @-webkit-keyframes cssAnimation {
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  