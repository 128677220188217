*{
  border: 0px;
}

h4 {
  text-align: center;
}
.opening-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vmin;
  font-size: 12pt;
  padding: 35px;
}

.open-sections{
  width: 100%;
}

.maincontainer{
  
}

.intro-websites:hover {
  animation: bounce .4s 1 ease-in-out; 
  -webkit-animation: bounce .4s 1 ease-in-out; 
  -moz-animation: bounce .4s 1 ease-in-out; 
  -o-animation: bounce .4s 1 ease-in-out; 
}

.body__banner{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.body__banner_card{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: table-cell;
  max-width: 22%;
  border: solid 1px black;
  margin: 7px;
}
.body__banner_card img {
  width: 100%;
  height: auto;
}

.art_cards{
  display: flex;
  margin: 25px;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
}

.popup_b {
  border: solid 1px black !important;
  border-radius: 5px !important;
}

.body__header{

  padding: 15px;
  margin: 35px;
  width: 95%;
  background: white;

  border-width: 16px;
  border-style: solid;
  border-image: url('./images/bg_matt.png');
  border-image-slice: 16;
}

//CSS3 Bounce Animation
@-webkit-keyframes bounce {
    0%,  
    100% {
       -webkit-transform: translateY(0);
    } 
    50% {
        -webkit-transform: translateY(-3px);
    }
}
@-moz-keyframes bounce {
    0%, 
    100% {
       -moz-transform: translateY(0);
    }
    50% {
       -moz-transform: translateY(-3px);
    }
}
@-o-keyframes bounce {
    0%,
    100% {
       -o-transform: translateY(0);
    }
    50% {
       -o-transform: translateY(-3px);
    }
}
@keyframes bounce {
    0%,  
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  
  background-color: #d0cfce;
  font-family: 'Lato', sans-serif;
}



#icon {
position: absolute;
bottom: 0;
margin: auto;
z-index: -1;
}

 /*  Icon Drop    ########################################         */

#field {
  z-index: 5;
  width: 32px;
  height: 95vh;
  overflow: hidden;
  position: fixed;
  bottom: 15px;
  background-image:url("./images/wg_icon2.png");
  background-repeat:no-repeat;
  background-position:bottom;
  left: 15px;
}

#ball {
  position: absolute;
}

 /*  NAVBAR    ########################################         */

.get_art {
	box-shadow:inset 0px 4px 10px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:3px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#363636;
	font-family:Arial;
	font-size:14px;
	padding:10px 8px;
	text-decoration:none;
}
.get_art:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.get_art:active {
	position:relative;
	top:1px;
}

.donuts{
  text-align: right;
  width: 100%;
}

.welcome_wg {
  width: 100%;
  height: 30px;
  font-size: 18px;
  
}
h6 {
    
  list-style: none;
  color: rgb(0, 0, 0);
  float: inline-start;
  font-size: 28px;
  transition: 0.3s;
}



.right:hover {
    -webkit-transform: rotate(-30deg); 
-moz-transform: rotate(-30deg);
-webkit-transform:rotate(-30deg); /* Safari and Chrome */
    -o-transform:rotate(-30deg); /* Opera */
}

.sright:hover {
    -webkit-transform: rotate(-10deg); 
-moz-transform: rotate(-10deg);
-webkit-transform:rotate(-10deg); /* Safari and Chrome */
    -o-transform:rotate(-10deg); /* Opera */
}

.bright:hover {
    -webkit-transform: rotate(-50deg); 
-moz-transform: rotate(-50deg);
-webkit-transform:rotate(-50deg); /* Safari and Chrome */
    -o-transform:rotate(-50deg); /* Opera */
}

.left:hover {
    -webkit-transform: rotate(30deg); 
-moz-transform: rotate(30deg);
-webkit-transform:rotate(30deg); /* Safari and Chrome */
    -o-transform:rotate(30deg); /* Opera */
}
.sleft:hover {
    -webkit-transform: rotate(10deg); 
-moz-transform: rotate(10deg);
-webkit-transform:rotate(10deg); /* Safari and Chrome */
    -o-transform:rotate(10deg); /* Opera */
}
.bleft:hover {
    -webkit-transform: rotate(50deg); 
-moz-transform: rotate(50deg);
-webkit-transform:rotate(50deg); /* Safari and Chrome */
    -o-transform:rotate(50deg); /* Opera */
}